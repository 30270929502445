<template>
  <div style="min-height: 700px">
    <BreadCrumbs>خانه > ثبت نام</BreadCrumbs>
    <v-container style="max-width: 664px">
      <div class="register" >
        <p class="ma-0 my-8 primary--text" style="font-size: 14px">لطفا جهت ثبت نام در سامانه ایران آی سیب تمامی فیلدهای زیر را تکمیل نمائید.</p>

        <v-text-field
            background-color="white"
            label="نام شرکت / مجموعه"
            height="50"
            outlined
            :error-messages="titleErrors"
            v-model="title"
            @input="$v.title.$touch()"
            @blur="$v.title.$touch()"
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-select
            label="انتخاب حوزه فعالیت"
            solo
            :error-messages="catErrors"
            v-model="catId"
            :items="cats"
            item-text="title"
            item-value="id"
            @input="$v.catId.$touch()"
            @blur="$v.catId.$touch()"
            color="#f4f4f4"
            height="52"
            class="rounded-0"
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
          </template>
        </v-select>

        <v-text-field
            background-color="white"
            label="برند تجاری"
            height="50"
            :error-messages="brandErrors"
            v-model="brand"
            @input="$v.brand.$touch()"
            @blur="$v.brand.$touch()"
            outlined
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-text-field
            background-color="white"
            label="شماره تلفن ثابت"
            height="50"
            style="direction: ltr"
            v-model="fixedNumber"
            outlined
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-text-field
            background-color="white"
            label="شماره همراه"
            height="50"
            style="direction: ltr"
            v-model="coTell"
            outlined
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-text-field
            background-color="white"
            label="شماره فکس"
            height="50"
            style="direction: ltr"
            v-model="faxNumber"
            outlined
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-text-field
            background-color="white"
            :error-messages="companyemailErrors"
            label="ایمیل شرکت"
            height="50"
            style="direction: ltr"
            v-model="coEmail"
            outlined
            @input="$v.coEmail.$touch()"
            @blur="$v.coEmail.$touch()"
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <div class="siteurl">
        <v-text-field
            background-color="white"
            label="آدرس سایت"
            v-model="siteUrl"
            height="50"
            style="direction: ltr"
            outlined
            color="#570654"
            class="rounded-0 "
        >
          <template v-slot:prepend-inner>
            <p class="ma-0" >https://</p>
          </template>
        </v-text-field>
        </div>

        <div class="d-flex flex-column flex-sm-row align-center justify-space-between">

          <div class="selectsize" >

        <v-select
            label="انتخاب استان"

            solo
            :items="provinces"
            item-text="name"
            item-value="id"
            color="#f4f4f4"
            height="52"
            :error-messages="provinceErrors"
            v-model="provinceId"
            @input="$v.provinceId.$touch()"
            @blur="$v.provinceId.$touch()"
            class="rounded-0"
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
          </template>
        </v-select>
          </div>

          <div class="selectsize" >
        <v-select
            label="انتخاب شهر"
            solo
            :error-messages="cityErrors"
            v-model="cityId"
            @input="$v.cityId.$touch()"
            @blur="$v.cityId.$touch()"
            :items="cities"
            item-text="name"
            item-value="id"
            color="#f4f4f4"
            height="52"
            class="rounded-0"
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
          </template>
          <template v-slot:no-data>
            <div class="d-flex justify-center">
              <p class="ma-0 my-2" style="font-size: 14px;user-select: none">ابتدا استان را انتخاب کنید</p>
            </div>
          </template>
        </v-select>
          </div>

        </div>

        <v-textarea
            background-color="white"
            label="آدرس / نشانی"
            outlined
            :error-messages="addressErrors"
            v-model="address"
            @input="$v.address.$touch()"
            @blur="$v.address.$touch()"
            color="#570654"
            class="rounded-0 "
        ></v-textarea>

        <v-divider class="my-5" ></v-divider>

        <p class="mb-5" style="color:#a1a1a1; font-size: 14px" >اطلاعات کاربری</p>

        <v-text-field
            background-color="white"
            label="نام کاربری"
            height="50"
            outlined
            :rules="[usernameRules.pattern]"
            :error-messages="usernameErrors"
            v-model="username"
            @input="$v.username.$touch()"
            @blur="$v.username.$touch()"
            color="#570654"
            class="rounded-0"
        ></v-text-field>

        <v-text-field
            background-color="white"
            label="کلمه عبور"
            height="50"
            :error-messages="passErrors"
            v-model="password"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :type="passshow ? 'text' : 'password'"
            outlined
            color="#570654"
            class=" rounded-0 "
        >
          <template v-slot:append>
            <div style="cursor: pointer;margin-top: 3px" @click="passshow= !passshow" >
              <img v-if="passshow" src="../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
              <img v-else src="../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
            </div>
          </template>
        </v-text-field>

        <v-text-field
            background-color="white"
            label="تکرار کلمه عبور"
            height="50"
            v-model="repeatPassword"
            :error-messages="samepassErrors"
            @input="$v.repeatPassword.$touch()"
            @blur="$v.repeatPassword.$touch()"
            :type="passshow2 ? 'text' : 'password'"
            outlined
            color="#570654"
            class=" rounded-0 "
        >
          <template v-slot:append>
            <div style="cursor: pointer;margin-top: 3px" @click="passshow2= !passshow2" >
              <img v-if="passshow2" src="../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
              <img v-else src="../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
            </div>
          </template>
        </v-text-field>

        <v-text-field
            background-color="white"
            label="ایمیل کاربری"
            height="50"
            outlined
            style="direction: ltr"
            :error-messages="emailErrors"
            v-model="email"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            color="#570654"
            class="rounded-0 "
        ></v-text-field>

        <v-text-field
            background-color="white"
            label="شماره موبایل جهت فعالسازی"
            height="50"
            outlined
            :error-messages="tellErrors"
            @input="$v.tell.$touch()"
            @blur="$v.tell.$touch()"
            :rules="[ tellRules.pattern]"
            v-model="tell"
            color="#570654"
            class=" rounded-0"
        ></v-text-field>

        <v-btn
            min-width="158"
            class="btn mr-auto d-block my-3 "
            @click="signup"
        >
          ثبت نام
        </v-btn>


      </div>
    </v-container>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength,email } from 'vuelidate/lib/validators'
import axios from "axios";
export default {
  name: 'App',
  mixins : [validationMixin],
  data() {
    return {

      username : '',
      password: '',
      repeatPassword: '',
      address : '',
      email: '',
      coEmail : '',
      coTell : '',
      faxNumber : '',
      provinceId : '',
      cityId : '',
      title : '',
      brand : '',
      catId : '',
      fixedNumber : '',
      siteUrl : '',
      tell:'',
      tellRules: {
        pattern: value => {
          const pattern = /^(\+98|0)?9\d{9}$/
          return pattern.test(value) || 'شماره تماس نا معتبر است'
        },
      },
      usernameRules: {
        pattern: value => {
          const pattern = /^(?=.{6,20}$)(?![_.0-9])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/
          return pattern.test(value) || 'نام کاربری باید حاوی حروف مجاز و حداقل 6 کاراکتر باشد'
        },
      },

      passshow: false,
      passshow2: false,

      cats : [],
      provinces : [],
      cities : [],
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    },
    username:{
      required,
    },
    address:{
      required
    },
    email: {
      required,
      email
    },
    coEmail: {
      email
    },
    tell: {
      required,
    },
    provinceId : {
      required
    },
    cityId : {
      required
    },
    title : {
      required
    },
    brand : {
      required
    },
    catId : {
      required
    }

  },
  created() {
    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;
    })
    axios.get(this.$store.state.api + 'getprovince').then(response => {
      this.provinces = response.data.data;
    })
  },
  watch : {
    provinceId(newval , oldval){
      if(newval!==oldval){
        this.cities = [];
        axios.get(this.$store.state.api + 'getcity',{params : {id:this.provinceId}}).then(response => {
          this.cities = response.data.data;
        })
      }
    }
  },
  metaInfo() {
    return {
      title:'ثبت نام',
      link: [{rel: 'canonical', href: this.$store.state.url+'/register'}]
    }
  },
  components : {
    BreadCrumbs
  },
  methods : {
    signup(){
      this.$v.$touch();
      if (this.$v.$invalid || !this.numberValidate) {
        console.log('Err');
      } else {
        axios.post(this.$store.state.api + 'signup', {
          tell : this.tell,
          role : "work",
          username : this.username,
          password : this.password,
          email : this.email,
          title : this.title,
          brand : this.brand,
          siteUrl : this.siteUrl,
          fixedNumber : this.fixedNumber,
          faxNumber : this.faxNumber,
          coTell : this.coTell,
          coEmail : this.coEmail,
          address : this.address,
          provinceId : this.provinceId,
          cityId : this.cityId,
          catId : this.catId,
        }).then(response => {
          let user = response.data.data;
          if (response.data.result) {
            this.loading = false;
            localStorage.setItem('token', (user.userId + 1100) * 1234);
            this.$store.state.userId = user.userId;
            localStorage.setItem('title', (this.title));
            this.$store.state.userTitle = this.title;
            this.$store.state.notify.is = true;
            this.$store.state.notify.text = 'ثبت نام با موفقیت انجام شد';
            if (this.$router.currentRoute.name === 'register') {
              this.$router.push('/profile');
            }
          }else {
            this.loading = false;
            this.$store.state.notify.is = true;
            this.$store.state.notify.text = response.data.errors[0];
          }
        });
      }
    }
  },
  computed : {
    passErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('پسورد باید دارای حداقل 6 کاراکتر باشد')
      !this.$v.password.required && errors.push('پسورد را وارد کنید')
      return errors
    },
    samepassErrors () {
      const errors = []
      if (!this.$v.repeatPassword.$dirty) return errors
      !this.$v.repeatPassword.sameAsPassword && errors.push('تکرار پسورد همخوانی ندارد')
      return errors
    },
    provinceErrors () {
      const errors = []
      if (!this.$v.provinceId.$dirty) return errors
      !this.$v.provinceId.required && errors.push('استان را انتخاب کنید')
      return errors
    },
    cityErrors () {
      const errors = []
      if (!this.$v.cityId.$dirty) return errors
      !this.$v.cityId.required && errors.push('شهر را انتخاب کنید')
      return errors
    },
    titleErrors () {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push('نام شرکت/ مجموعه را وارد کنید')
      return errors
    },
    brandErrors () {
      const errors = []
      if (!this.$v.brand.$dirty) return errors
      !this.$v.brand.required && errors.push('نام برند تجاری را وارد کنید')
      return errors
    },
    catErrors () {
      const errors = []
      if (!this.$v.catId.$dirty) return errors
      !this.$v.catId.required && errors.push('حوزه فعالیت را انتخاب کنید')
      return errors
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required && errors.push('نام کاربری را وارد کنید')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      !this.$v.address.required && errors.push('آدرس را وارد کنید')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('ایمیل را به شکل صحیح وارد کنید')
      !this.$v.email.required && errors.push('ایمیل را وارد کنید')
      return errors
    },
    companyemailErrors () {
      const errors = []
      if (!this.$v.coEmail.$dirty) return errors
      !this.$v.coEmail.email && errors.push('ایمیل را به شکل صحیح وارد کنید')
      return errors
    },
    tellErrors () {
      const errors = []
      if (!this.$v.tell.$dirty) return errors
      !this.$v.tell.required && errors.push('شماره تماس را وارد کنید')
      return errors
    },
    numberValidate() {
      return this.tellRules.pattern(this.tell) === true && this.usernameRules.pattern(this.username) === true;
    },
  }
};
</script>

<style lang="scss">

.register{
  width: 100%;
  margin-top: 50px;
  margin-bottom: 124px;
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  transition: 0.3s all
}

@media only screen and (min-width: 660px){
  .register {
    padding: 15px 44px;
  }
}

.register .v-input .v-label{
  color: #a3a3a3 !important;
  font-size: 14px !important;
}

.register .v-text-field .v-label--active{
  color: #570654 !important;
}

.register .v-text-field fieldset{
  border-color: #e8e8e8 ;
}

.register .v-input--is-focused fieldset {
  border-color: #570654 !important;
}

.register .error--text fieldset {
  border-color: #ff6969 !important;
}

.register .v-input__slot{
  padding: 0 12px !important;
  margin-bottom: 4px !important;
}

.register .v-input__append-inner{
  margin-top: 12px !important;
}


.register .v-text-field__details{
  margin-bottom: 4px !important;
}

.register .v-input{
  font-size: 14px !important;
}

.register .v-list * , ::before , ::after {
  color: #570654 !important;
}

.register .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f6f6f6;
}

.register .v-select .v-input__append-inner{
  margin-top: 4px !important;
}

.register .v-select .v-input__slot{
  background-color: #f6f6f6 !important;
}

.register .v-select .v-label{
  color: #000 !important;
}

.register .v-select .v-input__slot {
  box-shadow: none !important;
  border : 1px solid #e8e8e8;
}

.selectsize{
  width: 100%;
}

@media only screen and (min-width: 600px){
  .selectsize{
    width: 267px;
  }
}

.siteurl .v-label--active {
  right: 0 !important;
}

.siteurl .v-input__prepend-inner {
  margin-top: 21px !important;
}

</style>
